import styled from 'styled-components'

export const Root = styled.section`
  background: ${(props) => props.theme.palette.default.bluedeep};
  padding: 0 ${(props) => props.theme.padding.default} 0;
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.margin.regular};
  svg {
    width: 100%;
    margin: 0 auto;
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    margin-top: 0;
  }
`

export const Desktop = styled.svg`
  display: none;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: flex;
  }
`
export const Mobile = styled.svg`
  display: flex;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`

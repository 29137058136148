import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

export default function useIsMobileDevice() {
  const themeContext = useContext(ThemeContext)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    //execute once on initial pageload
    window.innerWidth <= parseInt(themeContext.device.tablet)
      ? setIsMobile(true)
      : setIsMobile(false)
    //execute whenever the viewport is changed
    const detectDevice = () => {
      window.innerWidth <= parseInt(themeContext.device.tablet)
        ? setIsMobile(true)
        : setIsMobile(false)
    }
    window.addEventListener('resize', detectDevice)
  }, [])

  return isMobile
}
